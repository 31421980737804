import HOC from './HOC'
import './Bibliography.css'

export default function Bibliography() {
    return (
			<div className='Bibliography'>
				<HOC mercy={false}>
					<div className='content'>
						<h1>Under construction - coming soon!</h1>
					</div>
				</HOC>
			</div>
    )
}