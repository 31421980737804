import './Home.css';
import HOC from './HOC';
import docfold from '../images/‎mercy-docfold.jpeg';
import mercyCutout from '../images/Mercy website photo.png';

export default function Home() {
    return (
        <div className="Home">
          <HOC>
            <div className='content'>
              <div className='mercy'>
                <img src={mercyCutout} alt="mercy" />
              </div>

              <div className='title'>
                <h1>Celebrate</h1>
                <h1>Mercy Otis Warren</h1>
              </div>

              <div className='mission-contain'>
                <div className='mission'>
                  <h2>Our Mission</h2>
                  <p>Celebrate Mercy Otis Warren is dedicated to promoting the powerful spirit of the first woman to write a history of the American Revolution, through educational programming and community events. Our 2024 vision for the first year of our nonprofit includes building a membership base, creating a website, hosting lectures and historical interpretive opportunities, and generally spreading awareness about Mercy Otis Warren by partnering with local and state organizations like <a href='https://seeplymouth.com/' target='_blank'>SEEPLYMOUTH</a> and <a href='https://www.revolution250.org/' target='_blank'>REVOLUTION250</a>.</p>
                  <p>Our long term goals include local, state, and national recognition of Mercy Otis Warren, creating curriculum for K-12 schools, and the support and preservation of historic sites connected to the Otis and Warren families.</p>
                </div>

                <div className='email'>
                  <h2>Email Contact Information</h2>
                  <p>The Mercy Otis Warren Society - <b>celebratemercyotiswarren@gmail.com</b></p>
                </div>
              </div>
              {/* <div className='doc'>
                <img src={docfold} alt='docfold'></img>
                <div className='subtext'>
                  <p>Quincy August 17, 1814. The “Group” to my certain knowledge was written by Mrs. Warren of Plymouth. So certifies</p>                           
                  <p className='name'>John Adams</p>
                </div>
              </div> */}
            </div>
          </HOC>
        </div>
    )
}