import HOC from './HOC'
import './Join.css'
import mercyCutout from '../images/Mercy website photo.png';

export default function Join() {
    return (
			<div className='Join'>
				<HOC>
					<div className='content'>
						<div className='mercy'>
							<img src={mercyCutout} alt="mercy" />
						</div>

						<div className='title'>
								<h1>Join Us</h1>
						</div>

						{/* <div className='mission'>
							<h2>Our Mission</h2>
							<p>Celebrate Mercy Otis Warren is dedicated to promoting the powerful spirit of the first woman to write a history of the American Revolution, through educational programming and community events. Our 2024 vision for the first year of our nonprofit includes building a membership base, creating a website, hosting lectures and historical interpretive opportunities, and generally spreading awareness about Mercy Otis Warren by partnering with local and state organizations like <a href='https://seeplymouth.com/' target='_blank'>SEEPLYMOUTH</a> and <a href='https://www.revolution250.org/' target='_blank'>REVOLUTION250</a>.</p>
							<p>Our long term goals include local, state, and national recognition of Mercy Otis Warren, creating curriculum for K-12 schools, and the support and preservation of historic sites connected to the Otis and Warren families.</p>
						</div>

						<div className='email'>
							<h2>Email Contact Information</h2>
							<p>The Mercy Otis Warren Society - <b>celebratemercyotiswarren@gmail.com</b></p>
						</div> */}

						<div className='join-info'>
							<h2>Join The Mercy Otis Warren Society</h2>
							<p>In order for us to achieve our goals, we rely on the generous donations and the support of our membership.</p>
							<p>The annual membership fee is $25.00.</p>
							<p>If you or your company wish to become a founding member for $50.00, you will also receive a signed copy of Nancy Rubin Stuart’s award-winning biography: <i>The Muse of the Revolution: The Secret Pen of Mercy Otis Warren and the Founding of a Nation</i>.</p>
							
							<div className='sep'></div>
							<p>Membership Requests may be mailed to:</p>
							<p>P.O. Box 76</p>
							<p>Manomet, MA. 02354-0076</p>
							<p>The Mercy Otis Warren Society, LLC</p>

							<div className='sep'></div>

							<p><b>President:</b> Nancy Rubin Stuart</p>
							<p><b>Vice President:</b> Susan Ste. Marie</p>
							<p><b>Treasurer:</b> Doug Ozelius</p>
							<p><b>Secretary:</b> Michele Gabrielson</p>
							<p><b>Board:</b> Peggy M. Baker, Lea Filson, Terah Harootunian, Jonathan Lane, Lisa O’Hanley, Mike Welch</p>
						</div>

						<div className='email'>
							<h2>Join our Mailing List</h2>
							<p>For Newsletters and Upcoming Events please send an email to <b>celebratemercyotiswarren@gmail.com</b></p>
						</div>

						<div className='donate'>
							<h2>Donate</h2>
							<p>Make a donation to The Mercy Otis Warren Society</p>
							<p>Donations payable through PayPal at <b>celebratemercyotiswarren@gmail.com</b></p>
						</div>

						<div className='newsletters'>
							<h2>Newsletters</h2>
							<a href={`${process.env.PUBLIC_URL}/Freshest Advices Summer 2024.pdf`}>Freshest Advices: Volume 1, Issue 1, Summer 2024</a>
						</div>
					</div>
				</HOC>
			</div>
    )
}