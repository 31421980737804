import { useMemo } from 'react'
import './NavBar.css'
import { Link } from 'react-router-dom'

const NavButton = ({label, link}) => {
    return (
      <div className='nav-contain'>
        <Link to={link}>
          <div className={`navButton-hidden ${window.location.pathname === link ? 'shown' : ''}`}>
            <span>{label}</span>
          </div>
        </Link>
        <div className='navButton'>
          <span>{label}</span>
        </div>
      </div>
    )
  }

  const DropdownButton = ({label, menu}) => {
      return (
        <div className='nav-contain dropdown'>
          <a>
            <div className={`navButton-hidden`}>
              <span>{label}</span>
            </div>
          </a>
          <div className='navButton dropbtn'>
            <span>{label}</span>
          </div>
          <div className='dropdown-content-wrapper'>
            <div class="dropdown-content">
              {menu.map(item => <a href={item[1]} target={item[2] ? "_blank" : "_self"} rel="noopener noreferrer">{item[0]}</a>)}
            </div>
          </div>
        </div>
      )
    }

export default function NavBar() {
  const plays = useMemo(() => [
    [<span><b>The Adulateur, A Tragedy</b> (1773)</span>, `${process.env.PUBLIC_URL}/THE ADULATEUR.pdf`],
    [<span><b>The Defeat</b> (1773)</span>, `${process.env.PUBLIC_URL}/THE DEFEAT.pdf`],
    [<span><b>The Group, A Farce</b> (1775)</span>, `${process.env.PUBLIC_URL}/THE GROUP.pdf`],
    [<span><b>The Blockheads: or the Afrightened Officers, a Farce</b> (1776)</span>, `${process.env.PUBLIC_URL}/THE BLOCKHEADS.pdf`],
    [<span><b>The Motley Assembly, A Farce</b> (1779)</span>, `${process.env.PUBLIC_URL}/THE MOTLEY ASSEMBLY.pdf`],
    [<span><b>The Ladies of Castile</b> (1784)</span>, `${process.env.PUBLIC_URL}/THE LADIES OF CASTILE.pdf`],
    [<span><b>The Sack of Rome</b> (1785)</span>, `${process.env.PUBLIC_URL}/THE SACK OF ROME.pdf`]
  ], [])

  const books = useMemo(() => [
    [<span><b>Observations on the New Constitution, and on the Federal and State Conventions. By a Columbian Patriot</b> (1788)</span>, 'https://quod.lib.umich.edu/cgi/t/text/text-idx?c=evans;cc=evans;rgn=main;view=text;idno=N16431.0001.001', true],
    [<span><b>Poems, Dramatic and Miscellaneous</b> (1790)</span>, 'https://quod.lib.umich.edu/cgi/t/text/text-idx?c=evans;cc=evans;rgn=main;view=text;idno=N17785.0001.001', true],
    [<span><b>History of the Rise, Progress and Termination of the American Revolution, interspersed with Biographical and Moral Observations</b> (1805)</span>, 'https://babel.hathitrust.org/cgi/pt?id=uc2.ark:/13960/t57d2r66x&seq=9', true],
  ], [])

  const letters = useMemo(() => [
    [<span><b>Original Letters</b> (scanned by the Massachusetts Historical Society)</span>, 'https://www.masshist.org/collection-guides/view/fa0235?terms=mercy%20otis%20warren%20letters', true],
    [<span><b>Correspondence between John Adams and Mercy Warren relating to her History of the American Revolution</b> (1878)</span>, 'https://babel.hathitrust.org/cgi/pt?id=iau.31858001752991&seq=7', true],
    [<span><b>An Additional number of letters from the Federal farmer to the Republican, leading to a fair examination of the system of government, proposed by the late Convention; to several essential and necessary alterations in it; and calculated to illustrate and support the principles and positions laid down in the preceding letters. Together with Observations on the new Constitution, and on the Federal and State Conventions by a Columbian patriot.</b> (1788)</span>, 'https://babel.hathitrust.org/cgi/pt?id=mdp.39015010418534&seq=5', true],
  ], [])

  const photos = useMemo(() => [
    [<b>Mercy Otis Warren</b>, '/photos/mercy-otis-warren'],
    [<b>James Warren</b>, '/photos/james-warren'],
    [<b>John Adams</b>, '/photos/john-adams'],
    [<b>Thomas Jefferson</b>, '/photos/thomas-jefferson'],
    [<b>Other Revolutionaries</b>, '/photos/other-revolutionaries'],
    [<b>Original Documents</b>, '/photos/original-documents']
  ], [])

  const videos = useMemo(() => [
    [<b>Mercy Otis Warren: Muse of the Revolution</b>, 'https://www.youtube.com/watch?v=mGONzxVCrP0&t=1221s', true],
    [<b>Mercy Otis Warren & the Writings of the Revolution</b>, 'https://historycamp.org/michele-gabrielson-mercy-otis-warren-and-the-writings-of-a-revolutionary-american-calliope/', true],
    [<b>Revolutionary Gazette: Three Great Books</b>, 'https://www.youtube.com/watch?v=9l_OcEFbyYY&t=2s', true],
    [<b>Mercy Otis Warren: The Conscience of the Revolution</b>, 'https://www.youtube.com/watch?v=GsQuqOtdfG4&t=4s', true],
    [<b>CALLING HISTORY: Mercy Otis Warren 1  [podcast]</b>, 'https://www.callinghistory.com/e/mercy-otis-warren-part-1-history-shows-us-that-peaceful-times-controlled-by-men-don-t-seem-to-last/', true],
    [<b>CALLING HISTORY: Mercy Otis Warren 2  [podcast]</b>, 'https://www.callinghistory.com/e/mercy-otis-warren-part-2-liberty-must-reign-in-the-hearts-and-minds-of-the-people/, true']
  ], [])

    return (
        <div className="NavBar">
          <NavButton label="Home" link="/" />
          <DropdownButton label="Life" menu={[[<b>Read about Mercy's life</b>, `${process.env.PUBLIC_URL}/MercyOtisWarren_LIFE.pdf`]]} />
          <DropdownButton label="Plays" menu={plays} />
          <DropdownButton label="Books" menu={books} />
          <DropdownButton label="Letters" menu={letters} />
          {/* <DropdownButton label="Photos" menu={photos} /> */}
          <DropdownButton label="Media" menu={videos} />
          {/* <NavButton label="Bibliography" link="/bibliography" /> */}
          <NavButton label="Join" link="/join" />
        </div>
    )
}