import HOC from './HOC'
import './Photos.css'
// import logo from '../images/logo-retina.jpg';
import mercy1 from '../images/Mercy 1.jpg';
import mercy2 from '../images/Mercy 2.jpg';
import mercy3 from '../images/Mercy 3.jpg';
import mercy4 from '../images/Mercy 4.jpg';
import james1 from '../images/James Otis 1.jpg';
import james2 from '../images/James Otis 2.jpg';
import john1 from '../images/John Adams 1.jpg';
import thomas1 from '../images/Thomas Jefferson 1.jpg';
import object1 from '../images/Object 1.jpg';
import object2 from '../images/Object 2.jpg';
import object3 from '../images/Object 3.jpg';

export default function Photos() {
    return (
      <div className='Photos'>
        <HOC mercy={false}>
          {
            window.location.pathname === '/photos/mercy-otis-warren' ?
            <div className='content'>
              <img src={mercy1} alt='mercy 1'></img>
              <img src={mercy2} alt='mercy 2'></img>
              <img src={mercy3} alt='mercy 3'></img>
              <img src={mercy4} alt='mercy 4'></img>
            </div> :
            window.location.pathname === '/photos/james-warren' ? 
            <div className='content'>
              <img src={james1} alt='james 1'></img>
              <img src={james2} alt='james 2'></img>
            </div> :
            window.location.pathname === '/photos/john-adams' ? 
            <div className='content'>
              <img src={john1} alt='john 1'></img>
            </div> :
            window.location.pathname === '/photos/thomas-jefferson' ? 
            <div className='content'>
              <img src={thomas1} alt='thomas 1'></img>
            </div> :
            window.location.pathname === '/photos/other-revolutionaries' ? 
            <div className='content'>
              <h1>Under construction - coming soon!</h1>
            </div> :
            window.location.pathname === '/photos/original-documents' ? 
            <div className='content'>
              <img src={object1} alt='object 1'></img>
              <img src={object2} alt='object 2'></img>
              <img src={object3} alt='object 3'></img>
            </div> : null
            }
            {/* <div className='title'>
                <h1>Photos</h1>
            </div>

            <div className='subtitle'>
              <h2>{window.location.pathname.slice(8).split('-').map(word => word.toUpperCase()).join(' ')}</h2>
            </div> */}
        </HOC>
          
      </div>
    )
}