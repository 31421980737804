import './HOC.css'
import Footer from './Footer'
import Header from './Header'
import NavBar from './NavBar'
import { useEffect, useState } from 'react'
import bkgd from '../images/mercy-tall.jpeg';

export default function HOC({children, mercy=true}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.onresize = () => setWindowWidth(window.innerWidth)

    return (
      <div className='HOC'>

        {/* {mercy ? <div className='mercy'>
          <img src={mercyCutout} alt='mercy'></img>
        </div>: null} */}
        {/* <div id='bkgd' style={{backgroundImage: `url(${bkgd})`}}></div> */}
        <NavBar />
        <div className='hoc-children' >{children}</div>
        <Footer />
      </div>
    )
}