import './App.css';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useEffect, useMemo } from 'react';
import Home from './components/Home'
import Videos from './components/Videos';
import Join from './components/Join';
import Photos from './components/Photos';
import Bibliography from './components/Bibliography';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Home />} replace/>
          
          {/* <Route path="life" element={<Home />}/>
          <Route path="plays" element={<Home />}/>
          <Route path="books" element={<Home />}/>
          <Route path="letters" element={<Home />}/> 

          <Route path="videos" element={<Videos />}/> */}
          <Route path="bibliography" element={<Bibliography />}/>
          <Route path="join" element={<Join />}/>
          
          <Route path="photos/mercy-otis-warren" element={<Photos />}/>
          <Route path="photos/james-warren" element={<Photos />}/>
          <Route path="photos/john-adams" element={<Photos />}/>
          <Route path="photos/thomas-jefferson" element={<Photos />}/>
          <Route path="photos/other-revolutionaries" element={<Photos />}/>
          <Route path="photos/original-documents" element={<Photos />}/>

          <Route path="*" element={<Navigate to='/' />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
